var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-full pt-5 px-2"},[[_c('div',{staticClass:"flex w-full mx-2"},[_c('h1',{staticClass:"text-xl text-left font-extrabold mr-2"},[_vm._v(" Performance Moderation ")]),_c('Breadcrumb',{attrs:{"items":_vm.breadcrumbs}})],1)],[_c('div',{staticClass:"w-full px-2 my-5"},[_c('Card',{staticClass:"p-5 pt-0"},[_c('CardFooter',{attrs:{"reloadcard":"","showcalendar":""}})],1)],1)],(_vm.isLoading)?[(_vm.isLoading)?_c('div',{staticClass:"h-full"},[_c('Loader',{attrs:{"size":"xxs","loader-image":false}})],1):_vm._e()]:[(_vm.items.length)?[_c('div',{staticClass:"w-full px-2"},[(_vm.items.length)?_c('Table',{staticClass:"w-full px-0",attrs:{"headers":_vm.headers,"items":_vm.items,"aria-label":"advance Table","has-checkbox":false,"has-number":false,"loading":false,"pagination-list":_vm.paginationList,"page-sync":true},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [(item.year)?_c('div',{staticClass:"font-semibold text-sm leading-5 tracking-widest"},[_c('span',{staticClass:"text-darkPurple"},[_vm._v(" "+_vm._s(item.data.year)+" ")])]):(item.periodStart)?_c('div',{staticClass:"flex flex-wrap"},[_c('span',{staticClass:"flex text-sm"},[_vm._v(" "+_vm._s(item.data.periodStart)+" ")])]):(item.status)?_c('div',[_c('Badge',{staticClass:"capitalize",staticStyle:{"margin-right":"12px","font-size":"14px","padding":"12px 10px","height":"35px","border-radius":"5px"},attrs:{"label":item.data.status,"variant":"primary","background-color":item.data.status === 'active'
                    ? `rgba(19, 181, 106, 0.15)`
                    : `rgba(135, 142, 153, 0.15)`,"color":item.data.status === 'active' ? '#13B56A' : '#333333'}})],1):(item.id)?_c('div',[_c('div',{staticClass:"flex pb-3 mb-1 px-3 more h-8 cursor-pointer",on:{"click":function($event){return _vm.$router.push({
                    name: _vm.reportingTo
                      ? 'ESSOrganisationModeration'
                      : 'EssCeoModerationDashboard',
                    params: { year: item.data.year, goalId: item.data.id },
                  })}}},[_c('icon',{staticClass:"-mt-1 -ml-2 text-blueCrayola",staticStyle:{"padding":"3px 10px","width":"36px","height":"36px"},attrs:{"icon-name":"icon-eye","size":"xsm"}})],1)]):_vm._e()]}}],null,false,3031507339)}):_c('div',{staticClass:"flex flex-col justify-center items-center gap-5 px-10 py-10 mt-10"},[_c('icon',{staticStyle:{"width":"300px"},attrs:{"icon-name":"empty_agreement","size":"l"}}),_c('p',{staticClass:"font-normal text-base leading-5 text-jet text-center w-96"},[_vm._v(" Moderation Data not available. ")])],1)],1)]:_vm._e()]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }