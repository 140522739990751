export default{
  data() {
    return {
      queryString: '',
      currentYear: new Date().getFullYear()
    }
  },
  methods: {
    appraisalQuery() {
      return `?year=${this.currentYear}&operation=appraisal`;
    },
    agreementQuery() {
      return `?year=${this.currentYear}&operation=agreement`;
    },
  }
};
